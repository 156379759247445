<template>
  <div class="widgetContainer">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          {{ getSelectedAccount.label }}
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>
    <div class="widgetContainer__body">
      <div class="accountDetail__primary-account">
        <div class="body border-1">
          <div class="head">
            <p>{{ $t('acc_detail_acc_balance') }}</p>
            <span>{{ getSelectedAccount.totalBalance | currency }}</span>
          </div>
          <div class="numbers">
            <div>
              <p>{{ $t('acc_detail_num_title') }}</p>
              <span ref="accountNumber" id="accountNumber">
                {{ getSelectedAccount.accountNumber }}
                <span
                  class="icon-copy"
                  @click="
                    copyAction(
                      getSelectedAccount.accountNumber,
                      'Account number'
                    )
                  " />
              </span>
            </div>
            <div>
              <p>{{ $t('acc_detail_rout_title') }}</p>
              <span ref="routingNumber">
                {{ getSelectedAccount.routingNumber }}
                <span
                  class="icon-copy"
                  @click="
                    copyAction(
                      getSelectedAccount.routingNumber,
                      'Routing number'
                    )
                  " />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="accountDetail__spendAccount">
        <p class="header-title">
          {{ $t('acc_info_other_details') }}
        </p>
        <ul class="border-1">
          <li>
            <p class="label">
              {{ $t('acc_detail_acc_name') }}
            </p>
            <p class="value">
              {{ getSelectedAccount.label }}
            </p>
          </li>
          <!-- <li>
                        <p class="label">{{$t('account.text.accountNumber')}}</p>
                        <p class="value">{{getSelectedAccount.accountNumber}}</p>
                    </li>
                    <li>
                        <p class="label">{{$t('account.text.routingNumber')}}</p>
                        <p class="value">{{getSelectedAccount.routingNumber}}</p>
                    </li> -->
          <li>
            <p class="label">
              {{ $t('contact_AccountType_title') }}
            </p>
            <p class="value">
              {{ type }}
            </p>
          </li>
          <!-- <li>
                        <p class="label">{{$t('account.text.balance')}}</p>
                        <p class="value">${{getSelectedAccount.availableBalance}}</p>
                    </li> -->
          <li>
            <p class="label">
              {{ $t('acc_detail_date_opened') }}
            </p>
            <p class="value">
              {{
                getSelectedAccount.createdAt | date("MMM d, yyyy 'at' h:mm aa")
              }}
            </p>
          </li>
          <li>
            <p class="label">
              {{ $t('fund_row_sponsorbank') }}
            </p>
            <p class="value">
              {{ getSelectedAccount.sponsorBankName }}
            </p>
          </li>
          <li>
            <p class="label">
              {{ $t('acc_detail_interest') }}
            </p>
            <p class="value">
              {{ getSelectedAccount.interest }}% {{ $t('interest_apy') }}
            </p>
          </li>
          <li>
            <p class="label">
              {{ $t('acc_detail_fees') }}
            </p>
            <p class="value">
              {{ getSelectedAccount.fees | currency }}
              <span v-if="getSelectedAccount.accountInterestFrequency">/</span>
              {{ getSelectedAccount.accountInterestFrequency }}
            </p>
          </li>
        </ul>
      </div>

      <div class="listWithActionsv2">
        <p class="header-title">
          {{ $t('acc_info_action_title') }}
        </p>
        <ul>
          <li @click="statements">
            <div class="details">
              <p class="label">
                {{ $t('statements') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
          <li @click="openDisclosures()"
              v-if="isAccDetailDisclosureEnabled">
            <div class="details">
              <p class="label">
                {{ $t('profile_disclosures') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { accountType } from '@m/contact/enums';
import { getBrandConfig, getUIConfig } from '@/utils/env';

export default {
  name: 'AccountDetails',
  data() {
    return {
      isAccDetailDisclosureEnabled: getUIConfig('isAccDetailDisclosureEnabled')
    }
  },
  computed: {
    ...mapGetters('account', ['getSelectedAccount']),
    type() {
      return accountType[this.getSelectedAccount.type];
    }
  },
  methods: {
    statements() {
      //this.$emit('goTo', 'statement')
      this.drawerPush('statement');
      // this.$router.push('/account/statements');
    },
    copyAction(textToBeCopied, type) {
      const that = this;
      navigator.clipboard.writeText(textToBeCopied).then(
        function () {
          that.$notify({
            title: 'Success',
            message: `${type} copied`,
            type: 'success'
          });
        },
        function () {
          that.$apiErrorMessage(`Unable to copy ${type}`);
        }
      );
    },
    openDisclosures() {
      var win = window.open(
        getBrandConfig('disclosures','https://www.tranzactcard.com/terms-of-service.html'),
        '_blank'
      );
      win.focus();
    },
    close(){
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss">
.accountDetail {
  .header-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding-bottom: 6px;
  }

  &__primary-account {
    margin-bottom: 32px;

    .body {
      background-color: #fff;
      border-radius: var(--radius);
      padding: 16px;
    }

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 32px;

      p {
        color: var(--branding);
        font-weight: 500;
        line-height: 24px;
      }

      span {
        color: var(--color-green);
      }
    }

    .numbers {
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      p {
        color: var(--branding);
        line-height: 20px;
        padding-bottom: 4px;
      }

      div {
        > span {
          display: flex;
          align-items: center;
          font-weight: 500;
        }

        .icon-copy {
          padding-left: 7px;
          cursor: pointer;
        }
      }
    }
  }

  .loginHeader {
    .submit {
      margin-left: auto;
    }
  }

  &__spendAccount {
    margin-bottom: 32px;

    ul {
      background: #fff;
      border-radius: 10px;
      padding: 10px 16px;

      li {
        cursor: pointer;
        display: flex;
        font-size: 14px;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;

        .label {
          color: var(--branding);
        }

        .value {
          font-weight: 500;
          padding-left: 8px;
          word-break: break-word;
          width: 169px;
          text-align: left;
        }
      }
    }
  }

  &__actions {
    .main-title {
      text-transform: uppercase;
      color: rgba(#3c3c43, 0.6);
      font-size: 12px;
      line-height: 16px;
      padding: 43px 0 8px 0;
    }

    ul {
      border-radius: 10px;
      background-color: #fff;
    }

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 11px;

      .info {
        padding-left: 15px;

        .title {
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
        }

        .description {
          font-size: 12px;
          line-height: 16px;
          color: rgba(#3c3c43, 0.6);
        }
      }

      .icon-arrow-right {
        margin-left: auto;
        color: #c7c7c9;
        font-size: 13px;
      }
    }
  }
}
</style>
